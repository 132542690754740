<div *ngIf="!isLoggedIn">
  <div id="home" class="marginBottonWholeEm">
    <ngb-carousel #carousel interval="5000">
      <ng-template ngbSlide *ngFor="let img of images; let idx = index">
        <div class="carousel-caption">
          <div class="staticCarouselText right black">
            <div class="max-width-500px">
              <div>Welcome to {{ domain }}!</div>
              <h1 class="marginTop2 marginBottom0 green" *ngIf="(screenType !== 'phone')">
                Mixing. Mastering. Vocal Editing.
              </h1>
              <h2 class="marginTop2 marginBottom0 green" *ngIf="(screenType === 'phone')">
                Mixing. Mastering. Vocal Editing.
              </h2>
              <div>
                Fast. Professional. Easy to work with.
              </div>
            </div>
          </div>
        </div>
        <div class="faded-image image{{idx}}">
          <!-- 
            We don't put the url in here. Instead, we set background-image on the  
            imageX class (using code...) in order to implement a CSS fade effect...
          -->
          <img src=" " alt="" />
        </div>
      </ng-template>
    </ngb-carousel>
    <div class="marginTopHalfEm marginLeftWholeEm marginRightWholeEm marginBottonWholeEm"
    >
      <!--
          wackadooMaximizeContentHeight
          maxHeightThingBelowSelector=".wackadoo-footer"
          [maxHeightFudgeFactor]="-16"
      -->
      <h3 class="center green marginTopWholeEm" *ngIf="false">
        Spreading love, joy, hope, and a little soul... <span class="italic">one song at a time.</span>
      </h3>
      <h3 class="center green marginTopWholeEm">
        Helping you sound your absolute best!
      </h3>
      <div class="large marginTopWholeEm " [ngClass]="{ 'd-flex justify-content-around align-items-center' : (screenType !== 'phone')}">
        <div> 
          <div class="center  rotate_left">
              <div class="large">
                Mixing
              </div>
              <div class="marginBottomHalfEm" [ngClass]="{small:(screenType === 'phone')}">
                Got your tracks recorded? Congratulations! <span class="italic">(That was fun, wasn't it?)</span>
                Digging the vibe you get from that rough mix? Cool.
                You have prepped some amazing ingredients, but it's not yet that final dish.
              </div>
              <div class="marginBottomHalfEm" [ngClass]="{small:(screenType === 'phone')}">
                Now you need to blend all those tracks into a cohesive whole.
              </div>
          </div>
        </div>
        <div>
          <div class="center  rotate_right">
              <div class="large">
                Mastering
              </div>
              <div class="marginBottomHalfEm" [ngClass]="{small:(screenType === 'phone')}">
                So you've got your final mix done. The balance is just right - the tracks fit together seamlessly.
                Everything is EQ-ed just the way you want it. And of course, you left enough headroom for mastering.
              </div>
              <div class="marginBottomHalfEm" [ngClass]="{small:(screenType === 'phone')}">
                Now you just need that last little bit of polish on it - and to get it to industry-standard loudness.
              </div>
          </div>
        </div>
        <div>
          <div class="center rotate_left">
              <div class="large">
                Vocal Editing
              </div>
              <div class="marginBottomHalfEm" [ngClass]="{small:(screenType === 'phone')}">
                If you know what this is, you know how important it is.
                Manually adjusting your vocals for pitch and alignment  
                can make or break the overall perception of your song.
              </div>
              <div class="marginBottomHalfEm" [ngClass]="{small:(screenType === 'phone')}">
                Personally, I won't mix any of my own songs without it.
              </div>
          </div>
        </div>
      </div>
      <div class="large marginTopWholeEm d-flex justify-content-around align-items-center">
        <div class="center" [ngClass]="{ 'widthViewPort35' : (screenType !== 'phone') }">
          <div class="large">
            Let me prove myself to you.
          </div>
          <div class="marginBottomHalfEm" [ngClass]="{small:(screenType === 'phone')}">
            As a new client, you get your first song free. 
            Like what you hear? Then please hire me for your next one.
          </div>
          <div class="smallish italics marginBottomHalfEm" [ngClass]="{smallish:(screenType !== 'phone'), small:(screenType === 'phone')}">
            (On the off-chance you should happen to feel a little guilty about not paying for that first one?  
            OK, OK... I'll let you pay for it - but only because you insist. Really, you don't need to.)
          </div>
        </div>
      </div>
    </div>
  </div>
  <div id="Services" class="marginTopWholeEm marginBottonWholeEm">
    <wackadoo-audio-services></wackadoo-audio-services>
  </div>
  <div id="Portfolio" class="marginTopWholeEm marginBottonWholeEm">
    <wackadoo-audio-portfolio></wackadoo-audio-portfolio>
  </div>
  <div id="About" class="marginTopWholeEm marginBottonWholeEm">
    <wackadoo-audio-about-us></wackadoo-audio-about-us>
  </div>
  <div id="Contact" class="marginTopWholeEm marginBottonWholeEm">
    <wackadoo-audio-contact-us></wackadoo-audio-contact-us>
  </div>
</div>
<div class="marginWholeEm" *ngIf="user && isLoggedIn">
	<div class="marginWholeEm jumbotron shadow paddingTopWholeEm paddingBottomWholeEm">
		<h2>Welcome {{ user.fullName }}</h2>
		<h3> from {{ user.accountName }}!</h3>
		<br/>
		<p>
			userName: {{ user.userName }}
			<br/>
			login time: {{ user.lastAccess }})
		</p>
	</div>
</div>
