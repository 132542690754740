<div *ngIf="priceList">
  <div class="card">
    <div class="card-header">
      {{priceListTitle}}
    </div>
    <div class="card-body">
      <div *ngFor="let pli of priceList; let n = index;">
        <div class="border-bottom" *ngIf="pli">
          <div class="d-flex justify-content-between align-items-center">
            <div class="d-flex justify-content-between align-items-center" >
              <div class=""  [ngClass]="{ 'large marginLeftHalfEm marginRightHalfEm' : (screenType !== 'phone') ,  'marginLeftQuarterEm marginRightQuarterEm' : (screenType === 'phone') }" >
                <wackadoo-field [f]="pli.itemName" mode="read"></wackadoo-field>
              </div>
              <div class="marginLeftWholeEm marginTop2 small italic" *ngIf="(screenType === 'phone')" >
                <a class="link" (click)="togglePriceListCollapse(n)" [attr.aria-expanded]="!isCollapsed[n]">
                  (description)
                </a>
              </div>
            </div>
            <div class="d-flex justify-content-between">
              <div class="marginLeftHalfEm marginRightHalfEm" *ngIf="(pli.itemPrice.value !== 0)">
                <wackadoo-field [f]="pli.itemPrice" mode="read"></wackadoo-field>
              </div>
              <div class="italic marginLeftHalfEm marginRightHalfEm" *ngIf="(pli.itemPrice.value === 0)">
                call
              </div>
            </div>
          </div>
          <div *ngIf="(screenType !== 'phone')">
            <wackadoo-audio-price-list-items-content [resource]="pli"></wackadoo-audio-price-list-items-content>
          </div>
          <div *ngIf="(screenType === 'phone')" #collapse="ngbCollapse" [(ngbCollapse)]="isCollapsed[n]">
            <wackadoo-audio-price-list-items-content [resource]="pli"></wackadoo-audio-price-list-items-content>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
