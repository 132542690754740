import { Component, OnInit, OnDestroy } from '@angular/core';
import { ResourceComponent } from 'src/app/client-core/data/resource/resource.component';

@Component({
  selector: 'wackadoo-audio-price-list-items-content',
  templateUrl: './audio-price-list-items-content.component.html',
})
export class AudioPriceListItemsContentComponent extends ResourceComponent implements OnInit, OnDestroy {

  ngOnInit(): void {
    super.ngOnInit();
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
  }

}
