import { Component, OnInit, OnDestroy, Input, ElementRef, ChangeDetectorRef } from '@angular/core';
import { Subscription } from 'rxjs';
import { BannerComponent } from 'src/app/client-core/ui/banner/banner.component';
import { ViewportScroller } from '@angular/common';
import { UserAuthService } from 'src/app/client-core/services/user-auth.service';
import { UserInterfaceService } from 'src/app/client-core/services/user-interface.service';

@Component({
  selector: 'wackadoo-audio-banner',
  templateUrl: './audio-banner.component.html',
})
export class AudioBannerComponent extends BannerComponent implements OnInit, OnDestroy {

  @Input() parentElementRef: ElementRef = null;

  currentPage: string;
  currentPageSubscription: Subscription;

  currentTab: string = null;

  constructor(
    public _userAuthService: UserAuthService,
    public _userInterfaceService: UserInterfaceService,
    public _changeDetector: ChangeDetectorRef,
    public _viewportScroller: ViewportScroller,
  ) {
    super(_userAuthService, _userInterfaceService, _changeDetector);
  }

  ngOnInit(): void {
    super.ngOnInit();

    // console.log('audio banner init', this.appName, this.previousApp);

    this.currentPageSubscription = this._userInterfaceService.currentPage.subscribe(
      (currentPage: string) => {
        // console.log('audio banner: ' + JSON.stringify(currentPage));
        this.currentPage = currentPage;
      }
    );

  }

  ngOnDestroy(): void {
    if (this.currentPageSubscription) {
      this.currentPageSubscription.unsubscribe();
    }
    super.ngOnDestroy();
  }

  highlightBannerItem(bannerItem: string): boolean {
    let flag = false;
    if (this.currentPage !== 'home') {
      flag = (this.currentPage === bannerItem);
    } else {
      flag = (this.currentTab === bannerItem);
    }
    return flag;
  }

  loadPage(pageName: string): void {
    this.clickBannerMenu();
    this._userInterfaceService.loadPage(pageName);
  }

  scrollToTab(pageName: string): void {
    try {
      this.clickBannerMenu();
      if (this.currentPage === 'home') {
        // this._viewportScroller.scrollToAnchor(pageName);
        document.querySelector('#' + pageName).scrollIntoView({behavior: 'smooth'});
        this.currentTab = pageName;
      } else {
        this._userInterfaceService.loadPage('home');
        window.setTimeout(
          () => {
            // this._viewportScroller.scrollToAnchor(pageName);
            document.querySelector('#' + pageName).scrollIntoView({behavior: 'smooth'});
            this.currentTab = pageName;
          }
          , 100
        );
      }
    } catch (ex) {
      console.log(pageName, ex);
    }
  }

}
