<div class="card" *ngIf="resource" >
  <div class="card-header" [ngClass]="{ 'd-flex justify-content-between' : (screenType !== 'phone') }">
    <div class="nowrap">
      <wackadoo-field [f]="resource.songName" [mode]="mode" [size]="20"></wackadoo-field>
    </div>
    <div class="nowrap marginLeftHalfEm d-flex flex-row align-items-center">
      <div class="right marginRightHalfEm">
        Project
      </div>
      <div>
        <wackadoo-field [f]="resource.projectID" [mode]="mode"></wackadoo-field>
      </div>
    </div>
    <div class="nowrap marginLeftHalfEm d-flex flex-row align-items-center">
      <div class="right marginRightHalfEm">
        Client
      </div>
      <div>
        <wackadoo-field [f]="resource.clientID" [mode]="mode"></wackadoo-field>
      </div>
    </div>
  </div>
  <div class="card-body" >
    <div class="" [ngClass]="{ 'd-flex justify-content-around' : (screenType !== 'phone') }">
      <div>
        <div>
          <div class="nowrap marginLeftHalfEm marginTopQuarterEm marginBottomQuarterEm d-flex flex-row align-items-center">
            <div>
              <wackadoo-field [f]="resource.sampleRate" [mode]="mode" [size]="3"></wackadoo-field>
            </div>
            <div class="marginLeftQuarterEm">
              KHz
            </div>
          </div>
          <div class="nowrap marginLeftHalfEm marginTopQuarterEm marginBottomQuarterEm d-flex flex-row align-items-center">
            <div>
              <wackadoo-field [f]="resource.bitDepth" [mode]="mode" [size]="3"></wackadoo-field>
            </div>
            <div class="marginLeftQuarterEm">
              bits
            </div>
          </div>
        </div>
        <div>
          <div class="nowrap marginLeftHalfEm marginTopQuarterEm marginBottomQuarterEm d-flex flex-row align-items-center">
            <div class="max-width-3-em">
              <wackadoo-field [f]="resource.bpm" [mode]="mode"></wackadoo-field>
            </div>
            <div class="marginLeftQuarterEm">
              bpm
            </div>
          </div>
          <div class="nowrap marginLeftHalfEm marginTopQuarterEm marginBottomQuarterEm d-flex flex-row align-items-center">
            <div class="right marginRightQuarterEm">
              key:
            </div>
            <div>
              <wackadoo-field [f]="resource.mainKey" [mode]="mode" [size]="3"></wackadoo-field>
            </div>
          </div>
        </div>
      </div>
      <div class="center marginLeftHalfEm marginRightHalfEm">
        <div class="underline">
          Requested Services
        </div>
        <div class="d-flex justify-content-between">
          <div class="marginLeftHalfEm marginRightHalfEm">
            <div>
              Mix
            </div>
            <div>
              <wackadoo-field [f]="resource.mix" [mode]="mode" ></wackadoo-field>
            </div>
          </div>
          <div class="marginLeftHalfEm marginRightHalfEm" *ngIf="resource.mix.value">
            <div>
              VocalEdit
            </div>
            <div>
              <wackadoo-field [f]="resource.vocalEditing" [mode]="mode"></wackadoo-field>
            </div>
          </div>
          <div class="marginLeftHalfEm marginRightHalfEm">
            <div>
              Master
            </div>
            <div>
              <wackadoo-field [f]="resource.master" [mode]="mode"></wackadoo-field>
            </div>
          </div>
          <div class="marginLeftHalfEm marginRightHalfEm">
            <div>
              Complex
            </div>
            <div>
              <wackadoo-field [f]="resource.complex" [mode]="mode"></wackadoo-field>
            </div>
          </div>
        </div>
      </div>
      <div class="marginLeftHalfEm marginRightHalfEm">
        <div>
          Reference Track (style, feel, etc.)
        </div>
        <div>
          <wackadoo-field [f]="resource.referenceSong" [mode]="mode" [size]="32"></wackadoo-field>
        </div>
        <div>
          <wackadoo-field [f]="resource.referenceArtist" [mode]="mode" [size]="32"></wackadoo-field>
        </div>
      </div>
    </div>
    <div class="" [ngClass]="{ 'd-flex justify-content-around align-items-center' : (screenType !== 'phone') }">
      <div class="marginLeftHalfEm marginRightHalfEm paddingWholeEm fullWidth">
        <wackadoo-field-string [f]="resource.songNote" [mode]="mode" [forceWrap]="true"></wackadoo-field-string>
      </div>
    </div>
    <div class="paddingBottomHalfEm " [ngClass]="{ 'd-flex justify-content-around align-items-center' : (screenType !== 'phone') }">
      <div class="center">
        <div class="underline fullWidth">
          WAV File
        </div>
        <div class="marginTopHalfEm marginBottomHalfEm">
          <wackadoo-field-binary [f]="resource.wavFile" [mode]="mode" [resource]="resource"></wackadoo-field-binary>
        </div>
        <div class="marginTopHalfEm marginBottomHalfEm" [ngClass]="{ 'd-flex flex-row align-items-center' : (screenType !== 'phone') }">
          <div class="small marginRightHalfEm">
            Download Date
          </div>
          <div>
            <wackadoo-field [f]="resource.wavFileDownloadDate" [mode]="mode"></wackadoo-field>
          </div>
        </div>
      </div>
      <div class="center">
        <div class="underline fullWidth">
          MP3 File
        </div>
        <div class="marginTopHalfEm marginBottomHalfEm">
          <wackadoo-field-binary [f]="resource.mp3File" [mode]="mode" [resource]="resource"></wackadoo-field-binary>
        </div>
        <div class="marginTopHalfEm marginBottomHalfEm" [ngClass]="{ 'd-flex flex-row align-items-center' : (screenType !== 'phone') }">
          <div class="small marginRightHalfEm">
            Download Date
          </div>
          <div>
            <wackadoo-field [f]="resource.mp3FileDownloadDate" [mode]="mode"></wackadoo-field>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
