<div class="marginBottomHalfEm" *ngIf="resource">
  <ul class="smallish">
    <li *ngIf="resource.description1.isPopulated">
      <wackadoo-field [f]="resource.description1" mode="read"></wackadoo-field>
    </li>
    <li *ngIf="resource.description2.isPopulated">
      <wackadoo-field [f]="resource.description2" mode="read"></wackadoo-field>
    </li>
    <li *ngIf="resource.description3.isPopulated">
      <wackadoo-field [f]="resource.description3" mode="read"></wackadoo-field>
    </li>
    <li *ngIf="resource.description4.isPopulated">
      <wackadoo-field [f]="resource.description4" mode="read"></wackadoo-field>
    </li>
    <li *ngIf="resource.description5.isPopulated">
      <wackadoo-field [f]="resource.description5" mode="read"></wackadoo-field>
    </li>
    <li *ngIf="resource.description6.isPopulated">
      <wackadoo-field [f]="resource.description6" mode="read"></wackadoo-field>
    </li>
    <li *ngIf="resource.description7.isPopulated">
      <wackadoo-field [f]="resource.description7" mode="read"></wackadoo-field>
    </li>
    <li *ngIf="resource.description8.isPopulated">
      <wackadoo-field [f]="resource.description8" mode="read"></wackadoo-field>
    </li>
    <li *ngIf="resource.description9.isPopulated">
      <wackadoo-field [f]="resource.description9" mode="read"></wackadoo-field>
    </li>
    <li *ngIf="resource.description10.isPopulated">
      <wackadoo-field [f]="resource.description10" mode="read"></wackadoo-field>
    </li>
  </ul>
</div>
