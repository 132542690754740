import { Component, OnInit, OnDestroy } from '@angular/core';
import { DetailContentComponent } from 'src/app/client-core/pages/repository-page/detail-content/detail-content.component';
import { Subject, Subscription } from 'rxjs';
import { WField } from 'src/app/client-core/data/field.model';
import { ScreenType } from 'src/app/client-core/services/screen-type.enum';

@Component({
  selector: 'wackadoo-in-box-detail-content',
  templateUrl: './audio-in-box-detail-content.component.html',
})
export class AudioInBoxDetailContentComponent extends DetailContentComponent implements OnInit, OnDestroy {

  // this is here so that the repository-page can find it when loading dynamic content properly in an AOT build
  static componentNameUsedForDynamicContentInAOT = 'AudioInBoxDetailContentComponent';

  projectType = '';

  projectNameChangedSubject: Subject<WField> = null;
  projectNameChangedSubscription: Subscription = null;

  songCountChangedSubject: Subject<WField> = null;
  songCountChangedSubscription: Subscription = null;

  mixChangedSubject: Subject<WField> = null;
  mixChangedSubscription: Subscription = null;

  existingClientChangedSubject: Subject<WField> = null;
  existingClientChangedSubscription: Subscription = null;

  screenTypeChangedSubscription: Subscription = null;

  songOrder = [];

  // for bench test / debugging usage only...
  debug = false;

  ngOnInit(): void {
    super.ngOnInit();

    // public users can't see this field, and don't have permission to do the underlying lookup() event.
    if (this.user.isAdminOrAbove) {
      // We force existingClientID to include a "foreignType" lookup
      // but WITHOUT making it an actual "foreignKey"...
      if (this.debug) {
        console.log('InBoxDetailContent.ngOnInit() - existingClientID', this.resource.existingClientID);
      }
      if (this.resource.existingClientID) {
        this.userInterfaceService.convertToFakeForeignKeyField(this.resource, 'Client', 'existingClientID');
      }
    }

    // default is single, so set the appropriate song fields as required/not-required...
    this.setSongFieldRequiredFlags();

    // when changing the projectName, dynamically manage the first single name...

    this.projectNameChangedSubject = new Subject<WField>();
    this.projectNameChangedSubscription = this.projectNameChangedSubject.subscribe(
      (f: WField) => {
        if (this.debug) {
          console.log('projectNameChangedSubscription()', f);
        }
        // this fires as the user types in, hence the "startsWith()" portion of the validation...
        if (
            (Number(this.resource.numberOfSongs.value) === 1)
            && (
              !this.resource.songName1.isPopulated
              || f.value.startsWith(this.resource.songName1.value)
              || this.resource.songName1.value.startsWith(f.value)
            )
          ) {
          this.resource.songName1.value = f.value;
        }
      }
    );

    // dynamically manage the UX when the song count changes...

    this.songCountChangedSubject = new Subject<WField>();
    this.songCountChangedSubscription = this.songCountChangedSubject.subscribe(
      (f: WField) => {

        if (this.debug) {
          console.log('songCountChangedSubscription()', f);
        }

        if (f.name === 'numberOfSongs') {

          // weird bug on 1...
          if ((f.value === 1) || (f.value === '1')) {
            this.resource.projectType.value = 'single';
            this.resource.projectType.changed = true;
          } else if ((2 <= f.value) && (f.value <= 7)) {
            this.resource.projectType.value = 'EP';
            this.resource.projectType.changed = true;
          } else if ((7 < f.value) && (f.value <= 14)) {
            this.resource.projectType.value = 'album';
            this.resource.projectType.changed = true;
          }

        } else if (f.name === 'projectType') {

          if ((f.value === 'single') || (f.value === 'track')) {
            this.resource.numberOfSongs.value = 1;
            this.resource.numberOfSongs.changed = true;
          } else if ((f.value === 'EP') && (this.resource.numberOfSongs.value > 7)) {
            this.resource.numberOfSongs.value = 7;
            this.resource.numberOfSongs.changed = true;
          } else if ((f.value === 'EP') && (this.resource.numberOfSongs.value < 2)) {
            this.resource.numberOfSongs.value = 2;
            this.resource.numberOfSongs.changed = true;
          } else if ((f.value === 'album') && (this.resource.numberOfSongs.value < 7)) {
            this.resource.numberOfSongs.value = 8;
            this.resource.numberOfSongs.changed = true;
          }

        }

        this.setSongFieldRequiredFlags();

        this.setSongOrder();
      }
    );

    this.mixChangedSubject = new Subject<WField>();
    this.mixChangedSubscription = this.mixChangedSubject.subscribe(
      (f: WField) => {
        const n = Number(f.name.substr(3)); // trim off the 'mix'

        if (this.debug) {
          console.log('mixChangedSubscription()', f, n);
        }

        this.resource.getField('vocalEditing' + n).value = false;
        this.resource.getField('vocalEditing' + n).readOnly = !f.value;
      }
    );

    this.existingClientChangedSubject = new Subject<WField>();
    this.existingClientChangedSubscription = this.existingClientChangedSubject.subscribe(
      (f: WField) => {

        if (this.debug) {
          console.log('existingClientChangedSubscription()', f);
        }

        // set fields required for new customers...
        // set fields NOT required for existing customers...

        this.resource.clientFirstName.required = !f.value;
        this.resource.clientLastName.required = !f.value;
        this.resource.clientCompany.required = !f.value;
        this.resource.clientPhone.required = !f.value;
        this.resource.clientPassword.required = !f.value;
        this.resource.clientPasswordHint.required = !f.value;
      }
    );

    this.setSongOrder();

    this.screenTypeChangedSubscription = this.userInterfaceService.screenType.subscribe(
      (st: ScreenType) => {
        this.setSongOrder();
      }
    );
  }

  ngOnDestroy(): void {
    // console.log('Entering ngOnDestroy()');
    if (this.songCountChangedSubscription) {
      this.songCountChangedSubscription.unsubscribe();
    }
    if (this.projectNameChangedSubscription) {
      this.projectNameChangedSubscription.unsubscribe();
    }
    if (this.mixChangedSubscription) {
      this.mixChangedSubscription.unsubscribe();
    }
    if (this.existingClientChangedSubscription) {
      this.existingClientChangedSubscription.unsubscribe();
    }
    if (this.screenTypeChangedSubscription) {
      this.screenTypeChangedSubscription.unsubscribe();
    }
    super.ngOnDestroy();
  }

  setSongOrder(): void {
    if (this.debug) {
      console.log('setSongOrder', this.screenType, this.isSingleColumn);
    }
    this.songOrder = (this.isSingleColumn
                      ? [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14]
                      : [1, 3, 5, 7, 9, 11, 13, 2, 4, 6, 8, 10, 12, 14]
                    );
  }

  get isSingleColumn(): boolean {
    return (
            ((this.screenType !== 'fullscreen') || this.userInterfaceService.isSmallFullScreen())
            || (this.resource.numberOfSongs.value === '1')
            || (this.resource.numberOfSongs.value === 1)
          );
  }

  setSongFieldRequiredFlags(): void {

    // mark all the un-used song fields as NOT required and NOT changed...

    const n = this.resource.numberOfSongs.value;

    // mark all the fields in used songs as REQUIRED, and the fields in the un-used songs as NOT REQUIRED...

    for (let i = 1; i <= 14; i++) {
      // do we want to require ALL fields for the requested songs?
      for (const songfieldName of [
                    'songName'
                    // , 'sampleRate'
                    // , 'bitDepth'
                    // , 'bpm'
                    // , 'mainKey'
                    , 'mix'
                    , 'master'
                    , 'vocalEditing'
                    , 'complex'
                    // , 'referenceSong'
                    // , 'referenceArtist'
                    // , 'songNotes'     // song notes are never required...
                  ]) {

        const songField = this.resource.getField(songfieldName + i);
        if (songField) {
          songField.required = (i <= n ?  true : false);

          // special handling for reference songs... we REQUIRE 1, but ask for more...

          if ((songfieldName === 'referenceSong') || (songfieldName === 'referenceArtist')) {
            songField.required = (i === 1);
          }
        }

      }
    }

  }

  typeOf(x: any): string {
    return typeof(x);
  }

}
