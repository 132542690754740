<div [ngClass]="{ 'd-flex justify-content-around align-items-center' : (screenType !== 'phone') }">
  
  <div [ngClass]="{ 'widthViewPort50' : (screenType !== 'phone') }">
		<div class="card">
			<div class="card-header">
				Hello!
			</div>
			<div class="card-body">
        <div class="marginHalfEm">
          <p>
            And congratulations! You have found the most transparent <i>(to say nothing of 'self-deprecating'...)</i> website on the internet!
          </p>
          <p>
            According to the <a href="http://www.oxforddictionaries.com/definition/english/wackadoo" target="_blank">Oxford Dictionary</a>, 
            a "wackadoo" is "An eccentric or fanatical person". I think that fits, because I've this wackadoo idea stuck in my head that
            business should be simple, straight-forward and cooperative.
          </p>
          <div class="marginBottomWholeEm">
            As such, my goals are correspondingly direct:
            <ul>
              <li>
                To make your music sound as great - to <i>feel</i> as great - as it did in your head when you were first imagining it, 
                no matter where you play it - on ear buds, in a car, on the PA system of a Welsh football stadium, or right next to 
                your favorite artists on that streaming playlist.
              </li>
              <li>
                To earn your trust before I ask for your hard-earned money, because you don't know who I am, or how good a job I'm going to do for you. Yet.
              </li>
            </ul>
          </div>
          <p>
            I am so confident that you'll like what you hear that I'll do your first song for free.
          </p>
          <p>
            Full disclosure: I'm less experienced at this than I would like to be. However, I am <i>very actively</i> working to develop 
            my skills - hence the big yellow watermark on the website. <i>(BTW: In case you're wondering, this web site is fully operational. 
            It's <b>me</b> that's the work in progress...)</i>
            Once I have reached a sufficient level of proficiency, I will change some of the language on this website, take down the 
            big yellow watermark, and Photoshop in a little hair. <i>(OK, maybe not that last bit...)</i> And as I gain clients, I will 
            be adding examples to my (at this point...) rather sparesly populated Portfolio.
          </p>
          <p>
            So go ahead. Take a flier on me. You've got nothing to lose. See if I achieve my goals. If you think so, then please hire 
            me for your next project. But if you don't, then it has cost you nothing.
          </p>
          <p>
            I hope to hear from you.
          </p>
          <div>Thanks,</div>
          <div>Steve Kuenzli</div>
          <div>wackadoo.audio</div>
          <div><a href="mailto:steve@wackadoo.audio?subject=Enquiring about your services&body=Hey Steve,%0D%0A%0D%0AI was looking around your website, and was wondering...">steve@wackadoo.audio</a></div>
        </div>
			</div>
		</div>
  </div>
  <div [ngClass]="{ 'widthViewPort30' : (screenType !== 'phone') }">
    <div class="polaroid rotate_right marginTop4em">
      <img src="./assets/app-images/video frame.png" alt="Portrait Of An Old Man">
      <div class="italic">
        My ugly mug...
      </div>
    </div>
    <div class="polaroid rotate_left">
      <img src="./assets/app-images/cutaway (7).jpg" alt="5 string bass">
      <div class="italic">
        My primary instrument, when I play out...
      </div>
    </div>
  </div>
</div>
