import { Component, OnInit } from '@angular/core';
import { Globals } from 'src/app/client-core/services/global.service';
import { PageComponent } from 'src/app/client-core/pages/page/page.component';

@Component({
  selector: 'wackadoo-audio-contact-us',
  templateUrl: './audio-contact-us.component.html',
})
export class AudioContactUsComponent extends PageComponent implements OnInit {

  orgName = '';
  domain = '';

  ngOnInit(): void {
    super.ngOnInit();

    this.domain = Globals.domain;
    this.orgName = Globals.organizationName;
  }

}
