import { Component, OnInit } from '@angular/core';
import { DetailButtonBarComponent } from 'src/app/client-core/pages/repository-page/detail-button-bar/detail-button-bar.component';

@Component({
  selector: 'wackadoo-project-detail-button-bar',
  templateUrl: './project-detail-button-bar.component.html',
})
export class ProjectDetailButtonBarComponent extends DetailButtonBarComponent implements OnInit {

  // this is here so that the repository-page can find it when loading dynamic content properly in an AOT build
  static componentNameUsedForDynamicContentInAOT = 'ProjectDetailButtonBarComponent';

  ngOnInit(): void {
    super.ngOnInit();
  }

  public emailInvoice(): void {
    try {

      const title = 'Completion Notice: ' + this.resource.getField('projectName').value;

      const parms: any = this.resource.asParms;
      parms.templateName = 'Completion Notice';
      parms.to = parms.clientEmail;
      parms.subject = title;
      // I hate hard-coding this, but I'm the only user...
      parms.bcc = 'steve@wackadoo.audio';

      // EMailer is a modal dialog...
      this.modalDialogService.showEMailer('EMailer', parms, title);

    } catch (ex) {
      const msg = 'Projects.emailInvoice()';
      this.userInterfaceService.alertUserToException(ex, msg);
    }
  }
}
